.SubscribeToPremiumButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;

  font-weight: 600;
  border: none;
  height: 40px;
  border-radius: 10px;
  padding: 5px 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  background-color: var(--ice-blue);
  color: var(--white);
  box-shadow: 0 4px 0 0 rgb(35, 82, 124);
}

.SubscribeToPremiumButton:hover {
  background-color: rgb(97, 162, 220);
}

.SubscribeToPremiumButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 rgb(35, 82, 124);

} 